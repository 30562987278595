<template>
  <cs-page-docs class="view-docs-user">
    <AgreementDocs :type="2" />
  </cs-page-docs>
</template>

<script>
export default {
  components: {
    AgreementDocs: () => import("@/components/docs/AgreementDocs"),
  },
  data() {
    return {};
  },
};
</script>